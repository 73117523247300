var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "space-y-4"
  }, [_c('label', {
    staticClass: "block text-sm font-medium text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _c('div', {
    staticClass: "flex space-x-4 items-center"
  }, [_c('div', {
    staticClass: "relative max-w-sm w-full"
  }, [_c('div', {
    staticClass: "absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
  }, [_c('svg', {
    staticClass: "w-6 h-6 text-gray-500 dark:text-white",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "currentColor",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z",
      "clip-rule": "evenodd"
    }
  })])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.date,
      expression: "date"
    }],
    staticClass: "bg-gray-50 border border-gray-300 text-gray-500 pl-10 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",
    attrs: {
      "id": "datepicker-title",
      "type": "date",
      "max": _vm.maxDateProp ? _vm.maxDateProp.toISOString().split('T')[0] : null,
      "placeholder": "Select date"
    },
    domProps: {
      "value": _vm.date
    },
    on: {
      "change": _vm.updateDateTime,
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.date = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "relative max-w-sm w-full"
  }, [_c('div', {
    staticClass: "absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
  }, [_c('svg', {
    staticClass: "w-6 h-6 text-gray-500 dark:text-white",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "currentColor",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z",
      "clip-rule": "evenodd"
    }
  })])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.time,
      expression: "time"
    }],
    staticClass: "bg-gray-50 border border-gray-300 pl-10 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",
    attrs: {
      "type": "time"
    },
    domProps: {
      "value": _vm.time
    },
    on: {
      "change": _vm.updateDateTime,
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.time = $event.target.value;
      }
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }